import React, { useEffect, useState } from "react";
import { IMaskInput } from "react-imask";
import "swiper/css/bundle";
import "swiper/css";
import { APP_ROUTES } from "../../router/Route";
import "./Main.scss";
import "react-toastify/dist/ReactToastify.css";
import { Outlet, Link } from "react-router-dom";
//images
import euphoriaRefferal from "../../images/Euphoria-refferal.png";

//sections
import Header from "../Header/Header";
import Questions from "../Questions/Questions";
import { motion } from "framer-motion";
import styled from "styled-components";
import Tabs from "./Tabs";
import ParallaxText from "./FramerText";

import { useTranslation } from "react-i18next";
import TimelineComponent from "./timeline";
import CircularText from "./bioAdds";
import PartnersSlider from "./partners";
import ProductSlider from "./productSlider";
import { toast, ToastContainer } from "react-toastify";
import axios from "axios";
import IntroSection from "../IntroSection";
import ReviewSection from "../ReviewSection";

export const Title = styled(motion.h1)`
  font-family: var(--font-primary);
  color: white;
  font-size: 8em;
  z-index: 1;
  margin: 0;
  white-space: nowrap;
  margin-top: auto;
  transition: all 2s ease-in-out;
`;

function Main() {
  const { t } = useTranslation();
  const [changeLanguage, setChangeLanguage] = useState(false);
  const [isLoader, setIsLoader] = useState(true);
  const [isQuestions, setIsQuestions] = useState(false);
  const phoneMask = "+998 (00) 000-00-00";
  const Mask = [
    {
      mask: phoneMask,
    },
  ];

  // Side effects

  useEffect(() => {
    setIsLoader(false);

    const hash = window.location.hash;
    if (hash) {
      const sectionId = document.getElementById(hash.slice(1));
      if (sectionId) {
        sectionId.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, []);

  const [formData, setFormData] = useState({
    name: "",
    surname: "",
    phone: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const sanitizedPhone = formData.phone.replace(/[^\d+]/g, "");
    const sanitizedData = { ...formData, phone: sanitizedPhone };
    try {
      const response = await axios.post(
        "https://euphoria-group.uz/api/bid",
        sanitizedData
      );
      console.log("Form submitted successfully:", response.data);
      toast.success(`${t("tests.textAlert.alert4")}`);
      setFormData({
        name: "",
        surname: "",
        phone: "",
        message: "",
      });
    } catch (error) {
      console.error("Error submitting the form:", error);
      toast.error(`${t("tests.textAlert.alert5")}`);
    }
  };
  return (
    <>
      <ToastContainer />
      <Header
        hiddenLoader={isLoader}
        changeLanguage={changeLanguage}
        setChangeLanguage={setChangeLanguage}
      />
      <Questions visible={isQuestions} setIsQuestions={setIsQuestions} />
      <main onClick={() => setChangeLanguage(false)}>
        {/* ==== Intro Section ==== */}
        <IntroSection />
        {/* ==== Intro Section ==== */}

        <PartnersSlider />

        <section className="imgWithTxt" id="aboutUs">
          <div className="imgWithTxtHeading">
            <h2>{t("headings.bodySupport")}</h2>
          </div>
          <ProductSlider />
        </section>

        <CircularText setIsQuestions={setIsQuestions} />

        <ReviewSection />

        {/* Review Section */}

        <section className="sectioReferal">
          <div className="container">
            <div className="referalContainer">
              <div className="referalInfo">
                <div className="referalTitle">
                  <h3 className="title">{t("headings.referal")}</h3>
                </div>
                <div className="referalSubtitle">
                  <p className="subtitle">
                    {t("blocks.referalBlock.subtitle")}
                  </p>
                </div>
                <div className="referalLink">
                  <Link to={APP_ROUTES.REFERRAL}>
                    {t("blocks.referalBlock.buttonText")}
                  </Link>
                </div>
              </div>
              <div className="referalImage">
                <img
                  src={euphoriaRefferal}
                  alt={euphoriaRefferal}
                  loading="lazy"
                />
              </div>
            </div>
          </div>
        </section>

        <section className="sectionTimeline">
          <div className="container">
            <div className="sectionTimelineInfo">
              <div className="timelineInfo">
                <div className="sectionTitle">
                  <h3 className="title">{t("blocks.header.contacts")}</h3>
                </div>
              </div>
              <TimelineComponent />
              <div className="btnContainer">
                <Link to="/contacts" className="btn">
                  {t("blocks.referalBlock.buttonText")}
                </Link>
              </div>
            </div>
          </div>
        </section>

        <section className="survey">
          <div className="container">
            <div className="surveyHeading">
              <h2>{t("headings.whyWe")}</h2>
              <div className="surveyLine"></div>
            </div>
            <Tabs
              tab1={t("blocks.tabsBlock.title1")}
              tab2={t("blocks.tabsBlock.title2")}
              tab3={t("blocks.tabsBlock.title3")}
              tab4={t("blocks.tabsBlock.title4")}
              title1={t("blocks.tabsBlock.subtitle1")}
              subtitle1={t("blocks.tabsBlock.text1")}
              title2={t("blocks.tabsBlock.subtitle2")}
              subtitle2={t("blocks.tabsBlock.text2")}
              title3={t("blocks.tabsBlock.subtitle3")}
              subtitle3={t("blocks.tabsBlock.text3")}
              title4={t("blocks.tabsBlock.subtitle4")}
              subtitle4={t("blocks.tabsBlock.text4")}
            />
          </div>
          <div className="surveyText">
            <ParallaxText baseVelocity={-5}>Euphoria</ParallaxText>
          </div>
        </section>

        <section className="contactUs" id="contactUsSection">
          <div className="container">
            <div className="contactUsTxt">
              <h2>{t("headings.doYouNeedHelp")}</h2>
              <p>{t("blocks.contactsBlock.subtitle")}</p>
            </div>
            <div className="contactForm">
              <form className="form" onSubmit={handleSubmit}>
                <div className="formTitle">
                  <h2 className="title">
                    {t("blocks.contactsBlock.form.title")}
                  </h2>
                </div>
                <div className="inputBox">
                  <input
                    type="text"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    required
                  />
                  <span> {t("blocks.contactsBlock.form.name")}</span>
                </div>
                <div className="inputBox">
                  <input
                    type="text"
                    name="surname"
                    required
                    value={formData.surname}
                    onChange={handleChange}
                  />
                  <span>{t("blocks.contactsBlock.form.lastName")}</span>
                </div>
                <div className="inputBox">
                  <IMaskInput
                    type="tel"
                    name="phone"
                    value={formData.phone}
                    onChange={handleChange}
                    mask={Mask}
                    pattern="[0-9]{2}-[0-9]{3}-[0-9]{2}-[0-9]{2}"
                    maxLength={19}
                    required
                  />
                  <span>{t("blocks.contactsBlock.form.phone")}</span>
                </div>
                <div className="inputBox">
                  <input
                    name="message"
                    value={formData.message}
                    onChange={handleChange}
                    required
                  />
                  <span>{t("blocks.contactsBlock.form.message")}</span>
                </div>
                <div className="formBtn">
                  <button type="submit">
                    {t("blocks.contactsBlock.form.buttonText")}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </section>
      </main>
      <Outlet />
    </>
  );
}

export default Main;
