import React, { useMemo } from "react";
import "./style.sass";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const CircularText = (props) => {
  const { t } = useTranslation();

  const text = "EUPHORIA GROUP EUPHORIA GROUP EUPHORIA GROUP";
  const transformedText = useMemo(
    () =>
      text.split("").map((char, i) => (
        <span key={i} style={{ transform: `rotate(${i * 12}deg)` }}>
          {char}
        </span>
      )),
    [text]
  );

  return (
    <section className="marketing " id="marketingSection">
      <div className="container">
        <div className="marketingHeading">
          <div>
            <h2>{t("headings.bioAdds")}</h2>
            <p>
              {t("blocks.bioAdds.subtitle1")}
              <br />
              {t("blocks.bioAdds.subtitle2")}
            </p>
            <Link onClick={() => props.setIsQuestions(true)} className="br10">
              {t("blocks.bioAdds.buttonText")}
            </Link>
          </div>
          <div className="marketingImg">
            <div className="circle">
              <div className="logo"></div>
              <div className="text">
                <p>{transformedText}</p>
              </div>
            </div>
          </div>{" "}
        </div>
      </div>
    </section>
  );
};

export default CircularText;
