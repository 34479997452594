export const APP_ROUTES = {
  URL: "https://euphoria-group.uz/api",
  WELCOME: `/`,
  CATALOG: "/catalog",
  PRODUCT: "/product/:id",
  COLLECTION: "/collection/:id",
  CONTACTS: "/contacts",
  MARKETING: "/marketing",
  CALLCENTER: "/call-center",
  LOGISTICS: "/logistics",
};
