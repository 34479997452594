import React from "react";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/splide/css";

import arbit from "../../../images/3 (3).png";
import phoenix from "../../../images/PhoenixLogo 1.png";
import rcd from "../../../images/rcd 1 1.png";
import ofb from "../../../images/OFB-01 (2).png";
import anor from "../../../images/Anorbank-01.png";
import nbu from "../../../images/NBU-01_bdybU3Y.png";

import "./styles.sass";

const PartnersSlider = () => {
  const partnersLogos = [
    { id: 1, logo: arbit },
    { id: 2, logo: phoenix },
    { id: 3, logo: rcd },
    { id: 4, logo: ofb },
    { id: 5, logo: anor },
    { id: 6, logo: nbu },
  ];

  return (
    <div id="partners" className="container">
      <Splide
        options={{
          type: "loop",
          perPage: 6,
          perMove: 1,
          // autoplay: true,
          interval: 2000, // 2 seconds between slides
          speed: 1200, // 1.2 seconds for transition
          pagination: false,
          arrows: false,
          pauseOnHover: true, // Pause autoplay on hover
          breakpoints: {
            1430: { perPage: 5},
            1200: {
              perPage: 4,
            },

            911: {
              perPage: 3,
            },
            577: {
              perPage: 2,
              
            },

            400: {
              perPage: 1,
            },
          },
        }}
        className="partnersSlider"
      >
        {/* <div className="" > */}
        {partnersLogos.map((item, index) => (
          <SplideSlide key={index}>
            <div className="imagesBlock">
              <img src={item.logo} alt={`Logo ${item.id}`} className="img" />
            </div>
          </SplideSlide>
        ))}
        {/* </div> */}
      </Splide>
    </div>
  );
};

export default PartnersSlider;
