import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation } from "swiper/modules";

// Images
import quotationMark from "../../images/“.svg";
import girl1 from "../../images/girl1.jpg";
import girl2 from "../../images/girl2.jpg";
import boy1 from "../../images/boy1.jpg";
import boy2 from "../../images/boy2.jpg";
import boy3 from "../../images/boy3.jpg";
import commentsRight from "../../images/commentsRight.png";
import commentsLeft from "../../images/commnetsLeft.png";
import { useTranslation } from "react-i18next";

// Memoized ReviewSection to prevent unnecessary re-renders
const ReviewSection = React.memo(() => {
  const { t } = useTranslation();

  return (
    <section className="comments" id="commentsSection">
      <div className="commentsLeft commentsSlides comentsBgPosition">
        <div
          className="commentsBgWrapper comentsPositionUnder"
          style={{ backgroundImage: `url(${commentsLeft})` }}
        >
          <div className="commentsSideBgWrapper">
            <div className="commentsSideBgSecondWrapper">
              <Swiper
                modules={[Autoplay, Navigation]}
                speed={500} // Reduced speed for better performance
                loop={true}
                autoplay={{
                  delay: 5000, // Increased delay to reduce frequent slides
                  pauseOnMouseEnter: true,
                }}
                navigation={true}
                spaceBetween={30} // Reduced space between slides
                slidesPerView={1}
              >
                <SwiperSlide className="slideWrapper">
                  <div className="quote">
                    <img src={quotationMark} alt="Quote Mark" loading="lazy" />
                  </div>
                  <p>{t("blocks.reviewsSlider.text1")}</p>
                  <div className="iconPerson">
                    <img src={boy1} alt="Person" loading="lazy" />
                  </div>
                  <h3>{t("blocks.reviewsSlider.name1")}</h3>
                </SwiperSlide>

                <SwiperSlide className="slideWrapper">
                  <div className="quote">
                    <img src={quotationMark} alt="Quote Mark" loading="lazy" />
                  </div>
                  <p>{t("blocks.reviewsSlider.text2")}</p>
                  <div className="iconPerson">
                    <img src={girl1} alt="Person" loading="lazy" />
                  </div>
                  <h3>{t("blocks.reviewsSlider.name2")}</h3>
                </SwiperSlide>

                <SwiperSlide className="slideWrapper">
                  <div className="quote">
                    <img src={quotationMark} alt="Quote Mark" loading="lazy" />
                  </div>
                  <p>{t("blocks.reviewsSlider.text3")}</p>
                  <div className="iconPerson">
                    <img src={boy2} alt="Person" loading="lazy" />
                  </div>
                  <h3>{t("blocks.reviewsSlider.name3")}</h3>
                </SwiperSlide>

                <SwiperSlide className="slideWrapper">
                  <div className="quote">
                    <img src={quotationMark} alt="Quote Mark" loading="lazy" />
                  </div>
                  <p>{t("blocks.reviewsSlider.text4")}</p>
                  <div className="iconPerson">
                    <img src={boy3} alt="Person" loading="lazy" />
                  </div>
                  <h3>{t("blocks.reviewsSlider.name4")}</h3>
                </SwiperSlide>

                <SwiperSlide className="slideWrapper">
                  <div className="quote">
                    <img src={quotationMark} alt="Quote Mark" loading="lazy" />
                  </div>
                  <p>{t("blocks.reviewsSlider.text5")}</p>
                  <div className="iconPerson">
                    <img src={girl2} alt="Person" loading="lazy" />
                  </div>
                  <h3>{t("blocks.reviewsSlider.name5")}</h3>
                </SwiperSlide>
              </Swiper>
            </div>
          </div>
        </div>
      </div>

      <div className="commentsRight commentsSlides commetRightPosition">
        <div
          className="commentsBgWrapper commetRightPositionWrapper"
          style={{ backgroundImage: `url(${commentsRight})` }}
        >
          <div className="commentsSideBgWrapper">
            <div className="commentsSideBgSecondWrapper rightSwiperComments">
              <Swiper
                modules={[Autoplay, Navigation]}
                speed={500} // Reduced speed for better performance
                loop={true}
                autoplay={{
                  delay: 5000, // Increased delay to reduce frequent slides
                  pauseOnMouseEnter: true,
                }}
                navigation={true}
                spaceBetween={30} // Reduced space between slides
                slidesPerView={1}
              >
                <SwiperSlide className="slideWrapper">
                  <div className="quote">
                    <img src={quotationMark} alt="Quote Mark" loading="lazy" />
                  </div>
                  <p>{t("blocks.reviewsSlider.text2")}</p>
                  <div className="iconPerson">
                    <img src={girl1} alt="Person" loading="lazy" />
                  </div>
                  <h3>{t("blocks.reviewsSlider.name2")}</h3>
                </SwiperSlide>

                <SwiperSlide className="slideWrapper">
                  <div className="quote">
                    <img src={quotationMark} alt="Quote Mark" loading="lazy" />
                  </div>
                  <p>{t("blocks.reviewsSlider.text3")}</p>
                  <div className="iconPerson">
                    <img src={boy2} alt="Person" loading="lazy" />
                  </div>
                  <h3>{t("blocks.reviewsSlider.name3")}</h3>
                </SwiperSlide>

                <SwiperSlide className="slideWrapper">
                  <div className="quote">
                    <img src={quotationMark} alt="Quote Mark" loading="lazy" />
                  </div>
                  <p>{t("blocks.reviewsSlider.text4")}</p>
                  <div className="iconPerson">
                    <img src={boy3} alt="Person" loading="lazy" />
                  </div>
                  <h3>{t("blocks.reviewsSlider.name4")}</h3>
                </SwiperSlide>

                <SwiperSlide className="slideWrapper">
                  <div className="quote">
                    <img src={quotationMark} alt="Quote Mark" loading="lazy" />
                  </div>
                  <p>{t("blocks.reviewsSlider.text5")}</p>
                  <div className="iconPerson">
                    <img src={girl2} alt="Person" loading="lazy" />
                  </div>
                  <h3>{t("blocks.reviewsSlider.name5")}</h3>
                </SwiperSlide>

                <SwiperSlide className="slideWrapper">
                  <div className="quote">
                    <img src={quotationMark} alt="Quote Mark" loading="lazy" />
                  </div>
                  <p>{t("blocks.reviewsSlider.text1")}</p>
                  <div className="iconPerson">
                    <img src={boy1} alt="Person" loading="lazy" />
                  </div>
                  <h3>{t("blocks.reviewsSlider.name1")}</h3>
                </SwiperSlide>
              </Swiper>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
});

export default ReviewSection;
