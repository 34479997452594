// ProductSlider.jsx

import React from "react";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/splide/css"; // Import Splide's CSS
import VisuBefore from "../../../images/VisuCapsBefore.jpg";
import hypertBefore from "../../../images/hyperBefore.jpg";
import diabeticBefore from "../../../images/diabeticBefore.jpg";
import mensBefore from "../../../images/mensAfter.jpg";
import ParazitOFFBefore from "../../../images/ParazitOFFBefore.jpg";
import slimftBefore from "../../../images/slimftBefore.jpg";
import growBefore from "../../../images/geowBefore.jpg";
import ProductsCard from "../ProductsCard";
import { useTranslation } from "react-i18next";
import "./styles.module.scss";

const ProductSlider = () => {
  const { t } = useTranslation();

  const ProductSliderItems = [
    {
      id: 1,
      title: "Visu Caps",
      description: t("blocks.productsBlock.description2"),
      cardImage: VisuBefore,
      imageAlt: "Visu",
    },
    {
      id: 2,
      title: "Гипертофорт",
      description: t("blocks.productsBlock.description3"),
      cardImage: hypertBefore,
      imageAlt: "hypert",
    },
    {
      id: 3,
      title: "Диабетик Форте",
      description: t("blocks.productsBlock.description4"),
      cardImage: diabeticBefore,
      imageAlt: "diabetic",
    },
    {
      id: 4,
      title: "Mens Power",
      description: t("blocks.productsBlock.description5"),
      cardImage: mensBefore,
      imageAlt: "mens",
    },
    {
      id: 5,
      title: "ParazitOFF",
      description: t("blocks.productsBlock.description6"),
      cardImage: ParazitOFFBefore,
      imageAlt: "ParazitOFF",
    },
    {
      id: 6,
      title: "SLIMFIT",
      description: t("blocks.productsBlock.description7"),
      cardImage: slimftBefore,
      imageAlt: "slimft",
    },
    {
      id: 7,
      title: "Grow X",
      description: t("blocks.productsBlock.description8"),
      cardImage: growBefore,
      imageAlt: "Power",
    },
  ];

  return (
    <div className="product-slider-container" style={{ margin: "10px" }}>
      <section className="container">
        <Splide
          options={{
            type: "loop",
            perPage: 4, // Display 4 items per view
            perMove: 1,
            autoplay: true,
            interval: 5000, // Autoplay interval set to 5 seconds
            speed: 1200, // Transition speed of 1.2 seconds
            pagination: false, // Remove pagination dots
            arrows: false, // Remove navigation arrows
            pauseOnHover: true, // Pause autoplay when hovered
            gap: "30px", // Gap between slides
            padding: { left: 0, right: 0 }, // Ensure no extra padding on the sides
            breakpoints: {
              1200: { perPage: 4 },
              1100: { perPage: 3 },
              860: { perPage: 2 },
              400: { perPage: 1 },
              420: { perPage: 1 },
              0: { perPage: 1 },
            },
          }}
          className="product-slider"
        >
          {ProductSliderItems.map((item, index) => (
            <SplideSlide className="product-slide" key={index}>
              <ProductsCard
                cardImage={item.cardImage}
                imageAlt={item.imageAlt}
                title={item.title}
                description={item.description}
                link="/catalog"
              />
            </SplideSlide>
          ))}
        </Splide>
      </section>{" "}
    </div>
  );
};

export default ProductSlider;
