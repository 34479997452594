import React, { useRef, useMemo } from "react";
import "./styles.scss";
import {
  motion,
  useScroll,
  useSpring,
  useTransform,
  useMotionValue,
  useVelocity,
  useAnimationFrame,
} from "framer-motion";
import { wrap } from "@motionone/utils";

const ParallaxText = ({ children, baseVelocity = 100 }) => {
  const baseX = useMotionValue(0); // Motion value for horizontal position
  const { scrollY } = useScroll(); // Scroll value for vertical position
  const scrollVelocity = useVelocity(scrollY); // Get scroll velocity

  // Smooth the scroll velocity for better experience
  const smoothVelocity = useSpring(scrollVelocity, {
    damping: 50,
    stiffness: 400,
  });

  // Transform the velocity for effect scaling
  const velocityFactor = useTransform(smoothVelocity, [0, 1000], [0, 5], {
    clamp: false,
  });

  // Function to wrap motion values for parallax effect
  // Call the hook directly inside the component function body, not within useMemo
  const x = useTransform(baseX, (v) => `${wrap(-20, -200, v)}%`);

  // Direction factor reference
  const directionFactor = useRef(1);

  useAnimationFrame((t, delta) => {
    let moveBy = directionFactor.current * baseVelocity * (delta / 1000);

    if (velocityFactor.get() < 0) {
      directionFactor.current = -1;
    } else if (velocityFactor.get() > 0) {
      directionFactor.current = 1;
    }

    moveBy += directionFactor.current * moveBy * velocityFactor.get();

    baseX.set(baseX.get() + moveBy);
  });

  // Generate repeated text spans dynamically
  const repeatedText = useMemo(
    () =>
      Array.from({ length: 16 }).map((_, index) => (
        <span key={`parallax-span-${index}`}>{children}</span>
      )), // Change number to adjust repetitions
    [children]
  );

  return (
    <div className="parallax">
      <motion.div className="scroller" style={{ x }}>
        {repeatedText}
      </motion.div>
    </div>
  );
};

export default ParallaxText;
