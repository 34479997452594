import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import euphoriaLogo from "../../images/euphoria 1.png";
import globalIcon from "../../images/Global-Icon.svg";
import { APP_ROUTES } from "../../router/Route";
import { useNavigate } from "react-router-dom";
import "./Header.scss";
import rusFlag from "../../images/Rus-Flag.svg";
import uzbFlag from "../../images/Uzb-Flag.svg";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const flagMap = {
  ru: { flag: rusFlag, label: "Русский" },
  uz: { flag: uzbFlag, label: "Узбекча" },
};

const Header = (props) => {
  const navigation = useNavigate();
  const { t, i18n } = useTranslation();

  const handleLanguageChange = (language) => {
    i18n.changeLanguage(language);
    props.setChangeLanguage(false);
  };

  return (
    <>
      <div
        className={`loaderWrapper ${props.hiddenLoader ? "" : "hiddenLoader"}`}
      >
        <div className="loaderHeading">{t("main.reload")}!</div>
        <div className="loader"></div>
      </div>
      <header>
        <div className="header-wrapper container">
          <div
            className="header-logo"
            onClick={() => navigation(APP_ROUTES.WELCOME)}
          >
            <img
              src={euphoriaLogo}
              alt="euphoria-logo"
              style={{ marginLeft: "-28px" }}
            />
          </div>
          <div className="header-menu">
            <nav>
              <div className="navbar-container">
                <input id="menu__toggle" type="checkbox" />
                <label className="menu__btn" htmlFor="menu__toggle">
                  <span></span>
                </label>
                <ul className="menu__box">
                  {[
                    { route: APP_ROUTES.WELCOME, label: "blocks.header.main" },
                    {
                      route: APP_ROUTES.CATALOG,
                      label: "blocks.header.products",
                    },
                    {
                      route: APP_ROUTES.CALLCENTER,
                      label: "headings.callCenter",
                    },
                    {
                      route: APP_ROUTES.MARKETING,
                      label: "headings.marketing",
                    },
                    {
                      route: APP_ROUTES.LOGISTICS,
                      label: "blocks.header.logistic",
                    },
                    {
                      route: APP_ROUTES.CONTACTS,
                      label: "blocks.header.contacts",
                    },
                  ].map(({ route, label }) => (
                    <li key={route}>
                      <Link to={route}>{t(label)}</Link>
                    </li>
                  ))}
                </ul>
              </div>
            </nav>
            <div className="change-language">
              <img
                src={globalIcon}
                alt="Change language"
                effect="blur"
                onClick={() => props.setChangeLanguage(!props.changeLanguage)}
              />
              {props.changeLanguage && (
                <div className="change-language-block br10">
                  {Object.entries(flagMap).map(
                    ([language, { flag, label }]) => (
                      <button
                        key={language}
                        onClick={() => handleLanguageChange(language)}
                        className="change-language-block__button"
                      >
                        <p>{label}</p>
                        <LazyLoadImage
                          src={flag}
                          alt={language}
                          effect="blur"
                        />
                      </button>
                    )
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
