import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, EffectCards } from "swiper/modules";

import "swiper/css";
import "swiper/css/effect-cards";

import { APP_ROUTES } from "../../router/Route";
import "./introSection.modue.scss";

import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";

import urionBefore from "../../images/urionBefore.jpg";
import urionAfter from "../../images/urionAfter.jpg";
import VisuBefore from "../../images/VisuCapsBefore.jpg";
import VisuAfter from "../../images/VisecapsAfter.jpg";
import hypertBefore from "../../images/hyperBefore.jpg";
import hypertAfter from "../../images/hyperAfter.jpg";
import diabeticBefore from "../../images/diabeticBefore.jpg";
import diabeticAfter from "../../images/diabeticAfter.jpg";
import mensAfter from "../../images/mensBefore.jpg";
import mensBefore from "../../images/mensAfter.jpg";
import ParazitOFFBefore from "../../images/ParazitOFFBefore.jpg";
import ParazitOFFAfter from "../../images/ParazitOFFAfter.jpg";
import slimftBefore from "../../images/slimftBefore.jpg";
import slimftAfter from "../../images/slimftAfter.jpg";
import growBefore from "../../images/geowBefore.jpg";
import growAfter from "../../images/growAfter.jpg";
import { useTranslation } from "react-i18next";

function IntroSection() {
  const { t } = useTranslation();

  const mainBannerCardsItems = [
    {
      id: 1,
      imgBefore: urionBefore,
      imgAfter: urionAfter,
      sideTitle: "Urion",
      sideSubtitle: `${t("blocks.mainBlock.sale")}`,
    },
    {
      id: 2,
      imgBefore: VisuBefore,
      imgAfter: VisuAfter,
      sideTitle: "Visu Caps",
      sideSubtitle: `${t("blocks.mainBlock.sale")}`,
    },
    {
      id: 3,
      imgBefore: hypertBefore,
      imgAfter: hypertAfter,
      sideTitle: "Гипертофорт",
      sideSubtitle: `${t("blocks.mainBlock.sale")}`,
    },
    {
      id: 4,
      imgBefore: diabeticBefore,
      imgAfter: diabeticAfter,
      sideTitle: "Диабетик Форте",
      sideSubtitle: `${t("blocks.mainBlock.sale")}`,
    },
    {
      id: 5,
      imgBefore: mensBefore,
      imgAfter: mensAfter,
      sideTitle: "Mens Power",
      sideSubtitle: `${t("blocks.mainBlock.sale")}`,
    },
    {
      id: 6,
      imgBefore: ParazitOFFBefore,
      imgAfter: ParazitOFFAfter,
      sideTitle: "ParazitOFF",
      sideSubtitle: `${t("blocks.mainBlock.sale")}`,
    },
    {
      id: 7,
      imgBefore: slimftBefore,
      imgAfter: slimftAfter,
      sideTitle: "SLIMFIT",
      sideSubtitle: `${t("blocks.mainBlock.sale")}`,
    },
    {
      id: 8,
      imgBefore: growBefore,
      imgAfter: growAfter,
      sideTitle: "GrowX",
      sideSubtitle: `${t("blocks.mainBlock.sale")}`,
    },
  ];
  return (
    <section className="mainBanner">
      <div className="container">
        <div className="mainBannerHeading">
          <h1>{t("headings.main")}</h1>
          <p>{t("blocks.mainBlock.subtitle")}</p>
          <div className="mainBannerPartners">
            <Link to={APP_ROUTES.MARKETING} className="partner">
              {t("headings.marketing")}
            </Link>

            <Link to={APP_ROUTES.CALLCENTER} className="partner bigPartner">
              {t("headings.callCenter")}
            </Link>
            <Link to={APP_ROUTES.LOGISTICS} className="partner">
              {t("headings.logistic")}
            </Link>
          </div>
        </div>
        <div className="mainBannerCards">
          <Swiper
            modules={[EffectCards, Autoplay]}
            loop={true}
            speed={500}
            autoplay={{
              delay: 3000,
              pauseOnMouseEnter: true,
              disableOnInteraction: false,
            }}
            effect="cards"
            grabCursor={true}
            className="main-banner__swiper"
          >
            {mainBannerCardsItems.map((item) => (
              <SwiperSlide key={item.id}>
                <div className="slideWrapper">
                  <div className="">
                    <div className="swiperSlide">
                      <img
                        className="imgBefore"
                        src={item.imgBefore}
                        alt={item.imgBefore}
                      />
                      <img
                        className="imgAfter"
                        src={item.imgAfter}
                        alt={item.imgAfter}
                        loading="lazy"
                      />
                      <div className="sideTitle">
                        <div>{item.sideTitle}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </section>
  );
}

export default IntroSection;
