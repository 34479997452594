import React, { memo } from "react";
import "./styles.scss";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const ProductsCard = memo(
  ({ cardImage, imageAlt, title, description, link }) => {
    const { t } = useTranslation();
    return (
      <div className="benefitsCardMobile">
        <div className="productsCardInner">
          <Link to={link} className="flipCardFront">
            <div className="boxImage">
              <img src={cardImage} alt={imageAlt} loading="lazy" />
            </div>
            <div className="cardTitle">
              <h4 className="title" style={{whiteSpace:"nowrap"}}>{title}</h4>
            </div>
            <div className="cardText">
              <p className="text">{description}</p>
            </div>
            <div className="cardText">
              <span className="cardLink">
                {t("blocks.referalBlock.buttonText")}
              </span>
            </div>
          </Link>
        </div>
      </div>
    );
  }
);

export default ProductsCard;
